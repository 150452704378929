define("discourse/plugins/discourse-private-replies/discourse/connectors/topic-above-posts/private-replies-banner", ["exports", "@glimmer/component", "@ember/service", "@ember/helper", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _helper, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PrivateRepliesBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get hasPrivateReplies() {
      return this.args.outletArgs.model.get("private_replies");
    }
    get isUserViewLimited() {
      return this.hasPrivateReplies && this.args.outletArgs.model.get("private_replies_limited");
    }
    get whoCanSee() {
      return _discourseI18n.default.t("private_replies.topic_banner_line_2", {
        group: this.siteSettings.private_replies_topic_starter_primary_group_can_see_all ? _discourseI18n.default.t("private_replies.topic_banner_line_2_group") : "",
        participants: this.siteSettings.private_replies_participants_can_see_all ? _discourseI18n.default.t("private_replies.topic_banner_line_2_participants") : ""
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasPrivateReplies}}
          <div class="row">
            <div class="post-notice custom">
              {{icon 'user-secret'}}
              <div>
                <p>
                  {{#if this.isUserViewLimited}}
                    {{i18n 'private_replies.topic_banner_line_1'}}
                  {{else}}
                    {{i18n 'private_replies.topic_banner_line_1_all'}}
                  {{/if}}
                  <br>
                  {{ this.whoCanSee }}
                </p>
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "2Tda+L9A",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasPrivateReplies\"]],[[[1,\"      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"post-notice custom\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"user-secret\"],null]],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"isUserViewLimited\"]],[[[1,\"                \"],[1,[28,[32,1],[\"private_replies.topic_banner_line_1\"],null]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[28,[32,1],[\"private_replies.topic_banner_line_1_all\"],null]],[1,\"\\n\"]],[]]],[1,\"              \"],[10,\"br\"],[12],[13],[1,\"\\n              \"],[1,[30,0,[\"whoCanSee\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-private-replies/discourse/connectors/topic-above-posts/private-replies-banner.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PrivateRepliesBanner;
});